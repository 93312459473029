import {action, computed, observable, toJS} from 'mobx';
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';
import clone from 'lodash/cloneDeep';
import map from 'lodash/map';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import {ITag} from '@techsee/techsee-ui-common/lib/tags-panel';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {IDbHistory, IHistoryImageParams} from '../../../services/AngularServices/AngularServices';
import {ITranslate} from '../../../services/LocalizationService';
import {IResourceResponse, ISessionSummaryController, TSImageResource} from './SessionSummary.contracts';
import {IEventLogsService} from '../../../services/EventsLogService';
import {
    SessionSummaryLoggingService,
    ISessionSummaryLoggingService
} from '../../../services/SessionSummaryLoggingService';
import {IFieldModel, SimpleFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {INewSessionSummary} from '../../../../components/dashboard/ts-session-summary/ts-session-summary.service';
import {getRootStore} from '../../../app.bootstrap';

export class SessionSummaryController implements ISessionSummaryController {
    @observable private _screenshots: TSImageResource[] = [];

    @observable private _url: string = '';

    @observable private _isVisible: boolean = false;

    @observable private _isTagsOpen: boolean = false;

    @observable private _displayErrorMessage: boolean = false;

    @observable private _currentResource: Nullable<TSImageResource> = null;

    @observable private _disabledExpand = false;

    @observable private _tabIndex: number = 0;

    @observable newTagToAdd: IFieldModel = new SimpleFieldModel({
        value: ''
    });

    private readonly _commentMaxLength: number;

    private readonly _allowNewSessionSummary: boolean;

    private readonly _roomId: string;

    private readonly _translate: ITranslate;

    private readonly _dbHistory: IDbHistory;

    private _informationTags: ITag[] = [];

    private _eventLogger: ISessionSummaryLoggingService;

    private _isTablet: boolean;

    constructor(
        dbHistory: IDbHistory,
        eventLogService: Nullable<IEventLogsService>,
        roomId: string,
        translate: ITranslate,
        imageTags: ITag[],
        newSessionSummarySettings: INewSessionSummary
    ) {
        this._dbHistory = dbHistory;
        this._roomId = roomId;
        this._translate = translate;
        this._eventLogger = new SessionSummaryLoggingService(eventLogService, roomId);
        this._allowNewSessionSummary = newSessionSummarySettings.allowNewSessionSummary;
        this._disabledExpand = newSessionSummarySettings.allowNewSessionSummary;
        this._commentMaxLength = newSessionSummarySettings.descriptionMaxLength;

        const environmentDetect = getRootStore().environmentService;
        const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

        this._isTablet = environmentDetect.isTablet(displayTabletAsDesktop);

        this.setInformationTags(imageTags);
        this.setCurrentResource = this.setCurrentResource.bind(this);
        this.setIsMarked = this.setIsMarked.bind(this);
        this.setIsSelected = this.setIsSelected.bind(this);
        this.setTagsOpen = this.setTagsOpen.bind(this);
        this.addNewTag = this.addNewTag.bind(this);
        this.disableExpandButton = this.disableExpandButton.bind(this);
        this.setTabIndex = this.setTabIndex.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
    }

    get isTablet() {
        return this._isTablet;
    }

    get commentMaxLength() {
        return this._commentMaxLength;
    }

    get allowNewSessionSummary() {
        return this._allowNewSessionSummary;
    }

    @action
    onChangeComment(comment: string) {
        if (this._currentResource) {
            this._currentResource.comment = comment;
        }
    }

    @computed
    get tabIndex() {
        return this._tabIndex;
    }

    @action
    setTabIndex(index: number) {
        this._tabIndex = index;
    }

    @action
    disableExpandButton(value: boolean) {
        this._disabledExpand = value;
    }

    @computed
    get disabledExpand() {
        return this._disabledExpand;
    }

    @action
    addNewTag() {
        if (this.newTagToAdd && this.newTagToAdd.value) {
            const newTag: ITag = {
                id: this.newTagToAdd.value.toString(),
                text: this.newTagToAdd.value.toString(),
                className: 'selected'
            };

            map(this._screenshots, (screenshot: TSImageResource) => {
                if (screenshot._id === (this._currentResource && this._currentResource._id)) {
                    if (!screenshot.informationTags) {
                        screenshot.informationTags = [];
                    }

                    const tagExists = find(screenshot.informationTags, {id: newTag.id});

                    if (!tagExists) {
                        screenshot.informationTags.push(newTag);
                        this._currentResource = screenshot;
                    } else {
                        tagExists.className = 'selected';
                    }
                }
            });

            this._screenshots = observable.array(this._screenshots, {deep: true});

            this.newTagToAdd.setValue('');
        }
    }

    get translate() {
        return this._translate;
    }

    @computed
    get displayErrorMessage() {
        return this._displayErrorMessage;
    }

    @computed
    get countImageMarked() {
        return this._screenshots.filter((image) => image.isMarked).length;
    }

    @computed
    get screenshots() {
        return this._screenshots;
    }

    @computed
    get comment() {
        return (this._currentResource && this._currentResource.comment) || '';
    }

    @computed
    get currentResource() {
        return this._currentResource;
    }

    @computed
    get isSummaryVisible() {
        return this._isVisible;
    }

    @computed
    get isTagsOpen() {
        return this._isTagsOpen;
    }

    @computed
    get informationTags() {
        return this._informationTags;
    }

    @action
    setTagsOpen() {
        this._isTagsOpen = !this._isTagsOpen;
    }

    @action
    setCurrentResource(image: TSImageResource) {
        this._currentResource = image;
    }

    @action
    setScreenShots(screenshots: TSImageResource[]) {
        const resources = this.adjustScreenshots(screenshots).reverse();

        this._screenshots = observable.array(resources, {deep: true});

        if (this._screenshots && this._screenshots.length > 0) {
            this.setCurrentResource(this._screenshots[0]);
        }
    }

    @action
    showSessionSummary() {
        this._isVisible = true;
        this._eventLogger.log('Getting resources from history...');

        this.getAllResourceFromHistory()
            .then((historyRecord: IResourceResponse) => {
                this._eventLogger.log('Setting screenshots');
                this.setScreenShots(historyRecord.data && historyRecord.data.images);
            })
            .catch(() => {
                this._eventLogger.log('Getting resources from history failed!');

                this.setErrorMessage(true);
            });
    }

    @action
    hideSessionSummary() {
        this._isVisible = false;
        this._isTagsOpen = false;
        this._currentResource = null;
        this._screenshots = [];

        this._eventLogger.log('Closing session summary');
    }

    private getDataForSaving(item: TSImageResource, saveAll: boolean): IHistoryImageParams {
        const comment = item.comment;
        const dataForSaving: IHistoryImageParams = {data: {comment}};

        if (saveAll) {
            const isMarked = item.isMarked;
            const tags = filter(item.informationTags, (tag: ITag) => tag.className === 'selected');

            tags.map((tag: ITag) => delete tag.className);

            dataForSaving.data.isMarked = isMarked;
            dataForSaving.data.tags = tags;
        }

        return dataForSaving;
    }

    saveSessionData(saveAll: boolean): Promise<void> {
        this._eventLogger.log('Saving session summary');

        const promises: any = [];

        forEach(this.screenshots, (item) => {
            const jsItem = toJS(item);
            const tags = filter(item.informationTags, (tag: ITag) => tag.className === 'selected');

            tags.map((tag: ITag) => delete tag.className);

            const updateItemPromise = Promise.resolve()
                .then(() => this.getDataForSaving(jsItem, saveAll))
                .then((data) => this._dbHistory.update(item._id, data));

            promises.push(updateItemPromise);
        });

        return Promise.all(promises)
            .then(() => {
                this._eventLogger.log('Done saving session summary');

                return;
            })
            .catch(() => Promise.resolve());
    }

    @computed
    get badgeCount() {
        const selectedTags = filter(
            this.currentResource && this.currentResource.informationTags,
            (tag: ITag) => tag.className === 'selected'
        );

        return selectedTags.length && selectedTags.length;
    }

    @action
    setIsMarked(index: number) {
        if (index > -1) {
            this._screenshots[index].isMarked = !this._screenshots[index].isMarked;
        }
    }

    @action
    setIsSelected(index: number) {
        if (index > -1) {
            this._screenshots.map((screenshot) => (screenshot.isSelected = false));
            this._screenshots[index].isSelected = true;
        }
    }

    @action
    setSelectDeselectAll(value: boolean) {
        this._screenshots.forEach((item) => {
            item.isMarked = value;
        });
    }

    @action
    private setErrorMessage(value: boolean) {
        this._displayErrorMessage = value;
    }

    private getAllResourceFromHistory() {
        return this._dbHistory.room({
            params: {
                roomId: this._roomId
            }
        });
    }

    @action
    private setInformationTags(imageTags: ITag[]) {
        this._informationTags = clone(imageTags);

        this._informationTags.forEach((tag) => {
            tag.id = tag.text;
            tag.className = '';
        });
    }

    private adjustScreenshots(screenshots: TSImageResource[]) {
        const resources = clone(screenshots);

        resources.forEach((image: TSImageResource, index: number) => {
            const tags: ITag[] = [];

            if (image.tags) {
                image.tags.forEach((tag) => {
                    tags.push({text: tag.text, id: tag.text, className: 'selected'});
                });
            }

            image.comment = image.comment || '';
            image.isSelected = false;
            image.tags = tags;
            image.informationTags = unionBy(tags, clone(this._informationTags), 'id');

            if (!image.isMarked) {
                image.isMarked = false;
            }

            if (index === resources.length - 1) {
                image.isSelected = true;
            }
        });

        return resources;
    }
}
