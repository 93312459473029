import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {InviteInfoStepController} from './controller';
export interface InviteInfoToolbarProps {
    step: InviteInfoStepController;
}

@observer
export class InviteInfoToolbar extends TechseeBaseComponent<InviteInfoToolbarProps> {
    renderInternal(): JSX.Element {
        const {step} = this.props;

        // @ts-ignore
        const allowSendByWhatsApp = this.props.step._accInviteSettings.allowSendByWhatsApp;

        const addressIsEmailOrSms =
            step.currentAddressType === AddressTypesEnum.SMS ||
            step.currentAddressType === AddressTypesEnum.EMAIL ||
            step.currentAddressType === AddressTypesEnum.WHATSAPP;

        if (step.currentAddressType === AddressTypesEnum.OFFLINE) {
            return <React.Fragment />;
        }

        return (
            <div className={classNames('invite-info-toolbar', {'invite-info-toolbar_tabs': step.showInviteTabs})}>
                <div
                    className={classNames('invite-info-toolbar__wrapper', {
                        'invite-info-toolbar_short': !step.showInviteTabs
                    })}>
                    <div className='invite-info-toolbar__buttons'>
                        {addressIsEmailOrSms && (
                            <PrimaryButton onClick={step.onNextClick} disabled={step.isInProgress}>
                                {step.nextText}
                                {allowSendByWhatsApp && step.currentAddressType === AddressTypesEnum.WHATSAPP ? (
                                    <img className={'btn-img-whatsapp'} src={'../../img/whatsapp-invite-button.png'} />
                                ) : (
                                    <Icon iconName={'arrow-next'} />
                                )}
                            </PrimaryButton>
                        )}

                        {step.currentAddressType === AddressTypesEnum.INVITE_BY_CODE && (
                            <PrimaryButton onClick={step.onNextClick} disabled={step.isInProgress}>
                                {step.translate('REACT.INVITE.VIEW.GENERATE_CODE')} <Icon iconName={'arrow-next'} />
                            </PrimaryButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
