import {IEventLogsService} from './EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {Nullable} from '@techsee/techsee-common';

export interface ISessionSummaryLoggingService {
    readonly _eventLogService: Nullable<IEventLogsService>;
    readonly _roomId: string;

    log(description: string): void;
}

export class SessionSummaryLoggingService implements ISessionSummaryLoggingService {
    readonly _eventLogService: Nullable<IEventLogsService>;

    readonly _roomId: string;

    constructor(eventLogService: Nullable<IEventLogsService>, roomId: string) {
        this._eventLogService = eventLogService;
        this._roomId = roomId;
    }

    log(description: string): void {
        if (this._eventLogService) {
            this._eventLogService.info({
                logType: LOG_EVENTS.sessionSummary,
                roomId: this._roomId,
                meta: {description}
            });
        }
    }
}
