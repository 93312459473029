import includes from 'lodash/includes';
import values from 'lodash/values';
import assign from 'lodash/assign';
import EventEmitter from 'events';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {IAuthExpiryService} from '../../services/ts-auth-expiry/ts-auth-expiry.service';
import {IEventLogsService, EventLogParams} from './EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {postMessageMethod} from '@techsee/techsee-common/lib/constants/account.constants';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

export enum SessionActiveCodes {
    active = 0,
    unauthorized = 1,
    inactive = 2
}

enum SupportedEvents {
    isSessionActive = 'isSessionActive',
    SSOLoginStatus = 'TechSeeAuthStatus',
    // Note: prefix "mini" kept for backwards compatibility
    DashboardErrorPageLoaded = 'MiniDashboardErrorPageLoaded',
    PostResource = 'PostResource',
    getCurrentUrl = 'getCurrentUrl'
}

export enum MessagesStatus {
    Success = 'Success',
    Failed = 'Failed'
}

export enum MessageType {
    smsSent = 'smsSent',
    sessionConnected = 'sessionConnected',
    sessionEnded = 'sessionEnded',
    emailSent = 'emailSent',
    image = 'image'
}

interface ISessionEndedParams {
    type: MessageType;
    sessionId: string;
    techseeSessionId: string;
    customerNumber: string;
    customerId?: string;
    duration: number;
}
interface ImageParams {
    type: MessageType;
    techseeSessionId: string;
    fileName: string;
    base64encodedImage: string;
}

export interface ISMSSentParams {
    type: MessageType;
    techseeSessionId: string;
    customerNumber: string;
    customerId?: string;
    status: string;
    sessionType?: MeetingMode;
    error?: string;
}

export interface IEmailSentParams {
    type: MessageType;
    techseeSessionId: string;
    customerId?: string;
    status: string;
    sessionType?: MeetingMode;
    error?: string;
    to: string;
    url: string;
}

export interface IOCRSentParams {
    fileName: string;
    text: string;
    techseeSessionId: string;
}

export interface ISessionConnectedParams {
    type: MessageType;
    techseeSessionId: string;
    customerNumber: string;
    customerId?: string;
    sessionType?: MeetingMode;
}

export interface IJsApiServiceSettings {
    enabled: boolean;
    method: postMessageMethod;
    SSOStatusCheck: boolean;
    OTTStatusCheck: boolean;
    SMSSentCheck: boolean;
    EmailSentCheck: boolean;
    OCRSentCheck: boolean;
    sessionConnectedCheck: boolean;
    sessionEndedCheck: boolean;
    activeSessionCheck: boolean;
    dashboardErrorPageLoadedCheck: boolean;
    domainsWhitelist: string[];
    language: string;
    timezone: string;
    getCurrentUrl: boolean;
}

export interface IJsApiService {
    init(endMeetingCb: Function, settings: IJsApiServiceSettings): void;
    reset(): void;
    sessionStarted(): void;
    sessionEnded(params: ISessionEndedParams): void;
    SMSSent(params: ISMSSentParams): void;
    EmailSent(params: IEmailSentParams): void;
    OCRSent(params: IOCRSentParams): void;
    sessionConnected(params: ISessionConnectedParams): void;
    SSOLoginSuccess(authId: string): void;
    SSOLoginFailure(authId: string): void;
    OTTLoginSuccess(): void;
    dashboardErrorPageLoaded(): void;
    Image(params: ImageParams): void;
}

export class JsApiService extends EventEmitter implements IJsApiService {
    private _isSSOLoginSuccess: boolean = false;

    private _isSSOLoginFailure: boolean = false;

    private _isOTTLoginSuccess: boolean = false;

    private _OTTLoginTimestamp: Nullable<number> = null;

    private _dashboardErrorPageLoaded: boolean = false;

    private _SSOLoginTimestamp: Nullable<number> = null;

    private _SSOAuthId: Nullable<string> = null;

    private _isSessionStarted: boolean = false;

    private _isSessionEnded: boolean = false;

    private _endMeetingCb: Function = () => null;

    private _settings?: IJsApiServiceSettings;

    private _sessionStartHandling: boolean = false;

    private _sessionStartHandler: Function = () => null;

    private _initialized: boolean = false;

    constructor(
        private _authExpiryService: IAuthExpiryService,
        private _eventsLogService: IEventLogsService,
        private _browserUtilsService: IBrowserUtilsService
    ) {
        super();
    }

    private get postMessageTarget() {
        // Fallback to parent, especially in CRM integrations like zendesk where both popup and IFrame are used
        return this._settings?.method === postMessageMethod.PopupWindow
            ? window.opener || window.parent
            : window.parent;
    }

    private isTechSeeSessionActive(closeIfActive?: boolean): number {
        if (this._authExpiryService.isSessionExpired && !this._isSessionEnded) {
            return SessionActiveCodes.unauthorized;
        }

        const date = new Date();

        if (this._isSessionStarted && !this._isSessionEnded) {
            if (closeIfActive && this._endMeetingCb) {
                this._endMeetingCb();
            }

            const eventLog: EventLogParams = {
                logType: LOG_EVENTS.jsSessionActiveApiCheck,
                meta: {date, active: true}
            };

            this._eventsLogService.debug(eventLog);

            return SessionActiveCodes.active;
        }

        const eventLog: EventLogParams = {
            logType: LOG_EVENTS.jsSessionActiveApiCheck,
            meta: {date, active: false}
        };

        this._eventsLogService.debug(eventLog);

        return SessionActiveCodes.inactive;
    }

    private _emitEventSessionActive() {
        const activeSessionCheckEnabled = this._settings && this._settings.activeSessionCheck;

        if (!activeSessionCheckEnabled) {
            return;
        }

        const isActiveStatusCode = this.isTechSeeSessionActive(false);

        this.postMessageTarget.postMessage(
            {
                isActive: isActiveStatusCode === 0,
                statusCode: isActiveStatusCode
            },
            '*'
        );
    }

    private _emitEventSmsSent(message: ISMSSentParams) {
        const sendMessageEnabled = this._settings && this._settings.SMSSentCheck;
        const messageToSend = assign(message, {timestamp: this._getCurrentTimestamp()});

        if (!sendMessageEnabled) {
            return;
        }

        this.postMessageTarget.postMessage(messageToSend, '*');
    }

    private _emitEventEmailSent(message: IEmailSentParams) {
        const sendMessageEnabled = this._settings && this._settings.EmailSentCheck;
        const messageToSend = assign(message, {timestamp: this._getCurrentTimestamp()});

        if (!sendMessageEnabled) {
            return;
        }

        this.postMessageTarget.postMessage(messageToSend, '*');
    }

    private _emitEventOCRSent(message: IOCRSentParams) {
        const sendMessageEnabled = this._settings && this._settings.OCRSentCheck;
        const messageToSend = assign(message, {timestamp: this._getCurrentTimestamp()});

        if (!sendMessageEnabled) {
            return;
        }

        this.postMessageTarget.postMessage(messageToSend, '*');
    }

    private _emitEventSessionConnected(message: ISessionConnectedParams) {
        const sendMessageEnabled = this._settings && this._settings.sessionConnectedCheck;
        const messageToSend = assign(message, {timestamp: this._getCurrentTimestamp()});

        if (!sendMessageEnabled) {
            return;
        }

        if (!this._browserUtilsService.getFromSessionStorage('sessionStartedTimestamp')) {
            this._browserUtilsService.saveToSessionStorage('sessionStartedTimestamp', new Date().getTime());
        }

        this.postMessageTarget.postMessage(messageToSend, '*');
    }

    private _emitEventSessionEnded(message: ISessionEndedParams) {
        const emitEventEnabled = this._settings && this._settings.sessionEndedCheck;
        const messageToSend = assign(message, {timestamp: this._getCurrentTimestamp()});

        if (!emitEventEnabled) {
            return;
        }

        this.postMessageTarget.postMessage(messageToSend, '*');
    }

    private _emitEventSSOLoginStatus() {
        const SSOStatusCheckEnabled = this._settings && this._settings.SSOStatusCheck;

        if (!SSOStatusCheckEnabled || (!this._isSSOLoginSuccess && !this._isSSOLoginFailure)) {
            return;
        }

        this.postMessageTarget.postMessage(
            {
                event: 'TechSeeAuthStatus',
                timestamp: this._SSOLoginTimestamp,
                value: this._isSSOLoginSuccess ? 'ok' : 'error',
                authId: this._SSOAuthId
            },
            '*'
        );
    }

    private _emitEventOTTLoginStatus() {
        if (!(this._settings && this._settings.OTTStatusCheck)) {
            return;
        }

        this.postMessageTarget.postMessage(
            {
                event: 'TechSeeAuthStatus',
                timestamp: this._OTTLoginTimestamp,
                value: this._isOTTLoginSuccess ? 'ok' : 'error'
            },
            '*'
        );
    }

    private _emitEventDashboardErrorPageLoaded() {
        const dashboardErrorPageLoadedCheckEnabled = this._settings && this._settings.dashboardErrorPageLoadedCheck;

        if (!dashboardErrorPageLoadedCheckEnabled) {
            return;
        }

        this.postMessageTarget.postMessage(
            {
                // Note: prefix "mini" kept for backwards compatibility
                event: 'MiniDashboardErrorPageLoaded',
                value: this._dashboardErrorPageLoaded ? 'true' : 'false',
                authId: this._SSOAuthId
            },
            '*'
        );
    }

    private _emitEventGetCurrentUrl() {
        if (!(this._settings && this._settings.getCurrentUrl)) {
            return;
        }

        this.postMessageTarget.postMessage(
            {
                event: 'getCurrentUrl',
                timestamp: this._OTTLoginTimestamp,
                agentUrl: window.location.href
            },
            '*'
        );
    }

    private _emitImage(message: ImageParams) {
        this.postMessageTarget.postMessage(message, '*');
    }

    private _registerSessionEventsHandler() {
        this._sessionStartHandler = (e: MessageEvent) => {
            const domains = this._settings && this._settings.domainsWhitelist;
            const supportedEvents = values(SupportedEvents);

            const eventType = e.data?.type || e.data;

            if (!domains || !includes(supportedEvents, eventType) || !new RegExp(domains.join('|')).test(e.origin)) {
                return;
            }

            if (eventType === SupportedEvents.isSessionActive) {
                this._emitEventSessionActive();

                return;
            }

            if (eventType === SupportedEvents.SSOLoginStatus) {
                this._emitEventSSOLoginStatus();

                return;
            }

            if (eventType === SupportedEvents.DashboardErrorPageLoaded) {
                this._emitEventDashboardErrorPageLoaded();

                return;
            }

            if (eventType === SupportedEvents.PostResource) {
                const resourceUrl = e.data.value;

                this.emit('postResource', {resourceUrl});

                return;
            }

            if (eventType === SupportedEvents.getCurrentUrl) {
                this._emitEventGetCurrentUrl();

                return;
            }
        };

        // @ts-ignore
        window.addEventListener('message', this._sessionStartHandler);
        this._sessionStartHandling = true;
    }

    private _unregisterSessionEventsHandler() {
        if (this._sessionStartHandling) {
            // @ts-ignore
            window.removeEventListener('message', this._sessionStartHandler);
            this._sessionStartHandling = false;
        }

        const date = new Date();
        const eventLog: EventLogParams = {
            logType: LOG_EVENTS.jsSessionActiveApiFinish,
            meta: {date}
        };

        this._eventsLogService.debug(eventLog);
    }

    private _exposeFunctions() {
        (window as any).isTechSeeSessionActive = this.isTechSeeSessionActive.bind(this);
    }

    private _cleanFunctions() {
        delete (window as any).isTechSeeSessionActive;

        this._unregisterSessionEventsHandler();
    }

    private _getCurrentTimestamp() {
        const timezone = this._settings && this._settings.timezone;
        const date = new Date();
        let language = this._settings && this._settings.language && this._settings.language.toLowerCase();

        language = language && language.replace('_', '-');

        return language && timezone ? date.toLocaleString(language, {timeZone: timezone, hour12: false}) : date;
    }

    public init(endMeetingCb: Function, settings: IJsApiServiceSettings) {
        this._emitEventSessionActive = this._emitEventSessionActive.bind(this);
        this._emitEventSSOLoginStatus = this._emitEventSSOLoginStatus.bind(this);
        this._sessionStartHandler = this._sessionStartHandler.bind(this);

        this._settings = settings;
        this._endMeetingCb = endMeetingCb;
        this._registerSessionEventsHandler();
        this._exposeFunctions();

        const date = new Date();
        const eventLog: EventLogParams = {
            logType: LOG_EVENTS.jsSessionActiveApiInit,
            meta: {date}
        };

        this._eventsLogService.debug(eventLog);

        this._initialized = true;
    }

    public reset() {
        this._initialized = false;
        this._isSessionStarted = false;
        this._isSessionEnded = false;
        this._isSSOLoginSuccess = false;
        this._isSSOLoginFailure = false;
        this._isOTTLoginSuccess = false;
        this._dashboardErrorPageLoaded = false;
        this._SSOLoginTimestamp = null;
        this._OTTLoginTimestamp = null;
        this._SSOAuthId = null;
        this._endMeetingCb = () => null;
        this._cleanFunctions();
        this._browserUtilsService.removeFromSessionStorage('sessionStartedTimestamp');
    }

    public sessionStarted() {
        this._isSessionStarted = true;

        if (this._initialized) {
            this._emitEventSessionActive();
        }
    }

    public sessionEnded(params: ISessionEndedParams) {
        this._isSessionEnded = true;

        if (this._initialized) {
            const sessionStartedTimestamp = this._browserUtilsService.getFromSessionStorage('sessionStartedTimestamp');

            // eslint-disable-next-line radix
            params.duration = sessionStartedTimestamp ? (Date.now() - parseInt(sessionStartedTimestamp)) / 1000 : 0;
            this._emitEventSessionEnded(params);
            this._emitEventSessionActive();
        }
    }

    public SMSSent(params: ISMSSentParams) {
        if (this._initialized) {
            this._emitEventSmsSent(params);
        }
    }

    public EmailSent(params: IEmailSentParams) {
        if (this._initialized) {
            this._emitEventEmailSent(params);
        }
    }

    public OCRSent(params: IOCRSentParams) {
        if (this._initialized) {
            this._emitEventOCRSent(params);
        }
    }

    public sessionConnected(params: ISessionConnectedParams) {
        if (this._initialized) {
            this._emitEventSessionConnected(params);
        }
    }

    public SSOLoginSuccess(authId: string) {
        this._isSSOLoginSuccess = true;
        this._isSSOLoginFailure = false;
        this._SSOLoginTimestamp = Date.now();
        this._SSOAuthId = authId;

        if (this._initialized) {
            this._emitEventSSOLoginStatus();
        }
    }

    public SSOLoginFailure(authId: string) {
        this._isSSOLoginFailure = true;
        this._isSSOLoginSuccess = false;
        this._SSOLoginTimestamp = Date.now();
        this._SSOAuthId = authId;

        if (this._initialized) {
            this._emitEventSSOLoginStatus();
        }
    }

    public OTTLoginSuccess() {
        this._isOTTLoginSuccess = true;
        this._OTTLoginTimestamp = Date.now();

        if (this._initialized) {
            this._emitEventOTTLoginStatus();
        }
    }

    public dashboardErrorPageLoaded(): void {
        this._dashboardErrorPageLoaded = true;

        if (this._initialized) {
            this._emitEventDashboardErrorPageLoaded();
        }
    }

    public Image(params: ImageParams): void {
        if (this._initialized) {
            this._emitImage(params);
        }
    }
}
