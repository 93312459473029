import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {OverlayTrigger, Tooltip, Popover} from 'react-bootstrap';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {PLACEMENT_TYPE} from '@techsee/techsee-ui-common/lib/popover-panel';
import {BorderRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/border-rounded-icon';

import {
    AlignEnum,
    ILeftBarItemData,
    LeftBarControllerBase,
    DashboardSectionTypes,
    WizardSectionTypes
} from './controller';

import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

import {SMALL_ITEM_ICONS_UIDS} from './LeftBarBase.settings';

import './styles.scss';

export interface IItemLeftBar {
    controller: LeftBarControllerBase;
    itemData: ILeftBarItemData;
    alignItems: AlignEnum;
    sectionType: DashboardSectionTypes | WizardSectionTypes;
}

@observer
export class LeftBarItemComponent extends TechseeBaseComponent<IItemLeftBar> {
    private _ptr: any;

    constructor(props: IItemLeftBar) {
        super(props);

        this.handleItemClick = this.handleItemClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
    }

    handleItemClick() {
        this.props.controller.onLeftBarItemClicked(this.props.itemData, this.props.sectionType);
    }

    onMouseOver(state: boolean) {
        clearTimeout(this._ptr);
        this._ptr = setTimeout(() => {
            this.props.controller.onMouseOver(this.props.itemData, state);
        }, 0);
    }

    getIconByName(): ReactElement {
        const {itemData, alignItems} = this.props;
        const classProp = classNames('button-style section-item use-common-styles', itemData.uid, {
            disabled: itemData.isDisabled,
            active: itemData.isActive,
            selected: itemData.isSelected
        });

        const iconName = itemData.icon() as string;

        if (alignItems === AlignEnum.BOTTOM) {
            return (
                <BorderRoundedIconButton
                    onClick={this.handleItemClick}
                    className={classProp}
                    iconName={iconName}
                    colorName={IconColors.Blue}
                    sizeName={SMALL_ITEM_ICONS_UIDS.includes(itemData.uid) ? IconSizes.Small : IconSizes.Medium}
                    reverseColor={true}></BorderRoundedIconButton>
            );
        }

        return (
            <BorderRoundedIconButton
                onClick={this.handleItemClick}
                className={classProp}
                iconName={iconName}
                colorName={IconColors.White}
                sizeName={IconSizes.XLarge}
                noBorder={true}></BorderRoundedIconButton>
        );
    }

    getIconByElement(): ReactElement {
        const {itemData} = this.props;
        const customIconClass = classNames('left-bar-custom-icon', itemData.uid, {
            disabled: itemData.isDisabled,
            active: itemData.isActive,
            selected: itemData.isSelected
        });
        const Icon = () => itemData.icon() as ReactElement;

        return (
            <div className={customIconClass} onClick={this.handleItemClick}>
                <Icon />
            </div>
        );
    }

    getTooltip() {
        const {itemData} = this.props;

        return (
            <Tooltip
                className={`left-bar-tooltip left-bar-tooltip-${itemData.uid}`}
                id={`left-bar-tooltip-${itemData.uid}`}>
                {itemData.tooltip()}
            </Tooltip>
        );
    }

    getPopover() {
        const {itemData, sectionType, controller} = this.props;

        if (itemData.content) {
            const Content = itemData.content;

            return (
                <Popover
                    title={controller.getHeaderComponent(itemData, sectionType)}
                    onClick={controller.insideContent}
                    id={`left-bar-popover-${itemData.uid}`}
                    className={classNames(
                        'left-bar-popover',
                        `left-bar-popover-${itemData.uid}`,
                        `${itemData.hideContent && 'hide-content'}`,
                        {
                            'left-bar-popover-observer-connected':
                                itemData.isObserverConnected && sectionType === DashboardSectionTypes.OBSERVER
                        }
                    )}
                    placement={PLACEMENT_TYPE.RIGHT}>
                    <Content />
                </Popover>
            );
        }

        return <React.Fragment />;
    }

    renderInternal() {
        const {itemData, sectionType} = this.props;

        const ItemComponent =
            typeof itemData.icon() === 'string' ? () => this.getIconByName() : () => this.getIconByElement();

        if (itemData.content || itemData.tooltip()) {
            let OverlayComponent = <React.Fragment />;
            let trigger = ['hover'];

            if (itemData.isSelected && itemData.content) {
                trigger = ['click'];

                OverlayComponent = this.getPopover();
            } else if (itemData.isHovered) {
                OverlayComponent = this.getTooltip();
            }

            return (
                <OverlayTrigger trigger={trigger} placement={PLACEMENT_TYPE.RIGHT} overlay={OverlayComponent}>
                    <div onMouseEnter={() => this.onMouseOver(true)} onMouseLeave={() => this.onMouseOver(false)}>
                        {sectionType === DashboardSectionTypes.OBSERVER && itemData.isObserverConnected && (
                            <div className={'redIndicator'}></div>
                        )}
                        <ItemComponent />
                    </div>
                </OverlayTrigger>
            );
        }

        return <ItemComponent />;
    }
}
